<template>
  <div v-if="followers.length" class="followers-widget widget">
    <div class="d-flex">
      <label class="label">
        {{ $t("users.followers") }}
      </label>

      <v-spacer />

      <UserListModal
        v-if="total"
        :title="$t('users.followers')"
        action="follows/fetchFollowers"
        :params="{ id: user.id, params: {} }"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="number" v-on="on" v-bind="attrs">{{ total }}</div>
        </template>
      </UserListModal>
    </div>

    <div class="card pa">
      <!-- Loader -->
      <spinner v-if="loading" center />

      <!-- List -->
      <template v-else>
        <UserRow
          v-for="item in followers"
          :key="item.id"
          :user="item"
          :hideActions="user.id === $user.id"
          class="list-item"
        />
      </template>
    </div>
  </div>
</template>

<script>
import UserRow from "./UserRow.vue";
import UserListModal from "./UserListModal.vue";

export default {
  components: { UserRow, UserListModal },

  props: {
    user: Object,
  },

  data: () => ({
    followers: [],
    total: 0,
    loading: false,
  }),

  created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.loading = true;
      this.$store
        .dispatch("follows/fetchFollowers", {
          id: this.user.id,
          params: { per_page: 4 },
        })
        .then((data) => {
          this.followers = data.data;
          this.total = data.meta.total;
        })
        .catch(() => {
          this.$toast.error("Error while getting followers.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
