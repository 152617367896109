<template>
  <spinner v-if="!user" large center />
  <column-layout v-else class="page" sticky-sides :no-right="!canView">
    <!-- Left -->
    <template v-slot:left>
      <UserCard :user="user" class="mb-10" />

      <ImagesWidget v-if="canView" :user="user" />
    </template>

    <!-- Main -->
    <template v-if="canView">
      <PostForm v-if="user.id === $user.id" class="widget card pa mb-7" />

      <PostFeed
        :label="$t('posts.posts')"
        action="posts/fetch"
        :params="{ 'exact_search[user_id]': user.id }"
        :emptyText="$t('users.noPosts')"
      />
    </template>

    <template v-else>
      <v-alert color="primary" text>
        {{
          isBlocked
            ? $t("users.blockedProfileAlert")
            : $t("users.privateProfileAlert")
        }}
      </v-alert>
    </template>

    <!-- Right -->
    <template v-if="canView" v-slot:right>
      <FollowersWidget :user="user" />
    </template>
  </column-layout>
</template>

<script>
import FollowersWidget from "@/components/app/users/FollowersWidget.vue";
import PostFeed from "@/components/app/posts/PostFeed.vue";
import PostForm from "@/components/app/posts/PostForm.vue";
import UserCard from "@/components/app/users/UserCard.vue";
import ImagesWidget from "@/components/app/ImagesWidget.vue";

export default {
  metaInfo() {
    return {
      title: this.user ? this.user.username : null,
    };
  },

  components: {
    FollowersWidget,
    PostFeed,
    PostForm,
    UserCard,
    ImagesWidget,
  },

  data: () => ({
    user: null,
  }),

  computed: {
    canView() {
      return !this.isPrivate && !this.isBlocked;
    },

    isAuthUser() {
      return this.user.id === this.$user.id;
    },

    isBlocked() {
      return (
        this.user.is_blocked ||
        this.$user.blocked_users?.includes(String(this.user.id))
      );
    },

    isPrivate() {
      return (
        !this.isAuthUser &&
        this.user.profile_type === "private" &&
        !this.user.is_followed
      );
    },
  },

  watch: {
    $route() {
      this.fetchUser();
    },
  },

  created() {
    this.fetchUser();
  },

  methods: {
    fetchUser() {
      this.$store
        .dispatch("users/fetchById", this.$route.params.id)
        .then((user) => {
          this.user = user;
        })
        .catch((err) => {
          this.$utils.handleError(err);
        });
    },
  },
};
</script>

<style lang="scss"></style>
